<template>
  <div>

      <div class="container-lg">
        <div class="auth-container">
          <div class="auth-form">

                <h2>Set New Password</h2>
                <p>You're password should have at least 8 characters.</p>
                
                <!-- Password reset form -->
                <form>

                  <div class="form-group mb-3">
                    <label class="form-label">Enter new password<span class="p-1 required">*</span></label>
                    <input v-model="auth.password" type="password" class="form-control form-control-user" id="exampleInputPassword" aria-describedby="emailHelp">
                  </div>

                  <div class="form-group mb-3">
                    <label class="form-label">Repeat password<span class="p-1 required">*</span></label>
                    <input v-model="auth.confirmPassword" type="password" class="form-control form-control-user" id="exampleInputRepeatPassword" aria-describedby="emailHelp">
                  </div>
                  
                  <div class="mb-3">
                    <button 
                      class="custom-btn-lg custom-btn-secondary custom-btn-full custom-btn-rounded">
                      Reset Password
                    </button>
                  </div>

                </form>  

                <p class="login-link-small mt-3">
                  <router-link to="/login"><font-awesome-icon icon="arrow-left" />Return to login</router-link>    
                </p>                               

          </div><!-- End auth-form -->
        </div><!-- End auth-container -->

      </div><!-- End container-lg -->      

  </div>
</template>

<script>
export default {
    name: 'PasswordReset',
    data() {
      return {
        auth: {
          password: "",
          confirmPassword: ""
        }
      }
    },
  methods: {

  }
}
</script>